import React, { useState } from 'react';
import api from '../settings/AxiosSetup';

//Libraries
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../css/style.css';
import '../css/login.css';

const Login = () => {

    //Variables
    const blueColor = '#13A3F6';
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    //Functions
    const submitLogin = async () => {
        try {
            const response = await api.post('jwt-auth/v1/token?username=jarmo@groeier.nl&password=GPJR0809!');
            // Success
            if (response.data.token) {
              localStorage.setItem("bearerToken", JSON.stringify(response.data.token));
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }


    const getLocaties = async () => {
        try {
            const response = await api.get('api/locaties');
            console.log(response);
            // Success
            if (response.data.success) {
              
            }
          } catch (error) {
            console.log(error);
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }

    getLocaties();

    return(
        <div className="login">
            <div className="login-form">
                <img src={require('../assets/images/logo.png')} alt="Gemini Teak" />
                <div className="form">
                    <input
                        className="form-field"
                        type="text" 
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="E-mail"
                    />
                    <input
                        className="form-field"
                        type="password" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Wachtwoord"
                    />
                    

                    <button className="submit-login" onClick={submitLogin}>
                    {
                        user ?
                        <span>Inloggen</span>
                        :
                        <TailSpin
                        height="20"
                        width="20"
                        color="white"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        wrapperClass=""
                        visible={true}
                    />
                    }
                    </button>
                    
                </div>
            </div>
            <div className="image"></div>
        </div>
    );
}

export default Login;